<template>
  <div class="activity-container">
    <h2 class="title">Latest News</h2>

    <div class="container">
      <b-row class="card-box justify-content-center">
        <b-col xl="4" lg="6">
          <div class="card">
            <img src="./img/activity-icon/1.png" alt="" />
            <div class="content-wrapper">
              <p class="card-title">By <b>ZHEJIANG LAB</b> / 10 October 2024</p>
              <p class="card-content">
                The 4th Innovation Forum on Intelligent Computing
              </p>
            </div>
          </div>
        </b-col>
        <b-col xl="4" lg="6">
          <div class="card">
            <img src="./img/activity-icon/2.png" alt="" />
            <div class="content-wrapper">
              <p class="card-title">By <b>ZHEJIANG LAB</b> / 26 June 2024</p>
              <p class="card-content">
                Hatching insights: Large language models in life …
              </p>
            </div>
          </div>
        </b-col>
        <b-col xl="4" lg="6">
          <div class="card">
            <img src="./img/activity-icon/3.png" alt="" />
            <div class="content-wrapper">
              <p class="card-title">By <b>ZHEJIANG LAB</b> / 29 May 2023</p>
              <p class="card-content">
                Gather in London | UK Talents Exchange Fair In…
              </p>
            </div>
          </div>
        </b-col>
      </b-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "HomeActivityItems",
  components: {},
};
</script>
<style lang="scss" scoped>
.activity-container {
  max-width: 1296px;
  margin: 0 auto;
  padding-top: 50px;
  padding-bottom: 120px;
  background-color: #f9faf9;
  .title {
    line-height: 58px;
    font-size: 48px;
    font-weight: bold;
    font-family: Plus Jakarta Sans;
    color: #12141d;
  }
  .description {
    line-height: 24px;
    font-size: 16px;
    color: rgba(18, 20, 29, 0.7);
  }

  .card {
    height: auto;
    margin-top: 100px;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    border: none;
    img {
      display: block;
      width: 100%;
      height: auto;
    }
    .content-wrapper {
      padding: 30px;
      text-align: left;
      .card-title {
        font-size: 16px;
        line-height: 28px;
        color: #0f265d;
      }
      .card-content {
        line-height: 34px;
        font-size: 24px;
        font-weight: 900;
        font-family: Poppins;
        color: #0f265d;
        &.active {
          color: #2e4fff;
        }
      }
    }
  }
}
</style>
