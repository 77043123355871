<template>
  <div class="container" style="padding: 52px 0">
    <b-row>
      <b-col xl="3" lg="12">
        <logo-text />
      </b-col>
      <b-col xl="3" md="4">
        <span>Learn more</span>
        <a href="javascript:void(0);" @click="toPath('/about-us')">About us</a>
        <a href="javascript:void(0);" @click="toPath('/projects-programs')">
          Projects and Programs
        </a>
        <!-- <a href="javascript:void(0);">Resource Hub</a> -->
        <a href="javascript:void(0);" @click="toPath('/news-events')">
          News & Events
        </a>
      </b-col>
      <b-col xl="3" md="4">
        <span>Policy Guidelines</span>
        <a href="javascript:void(0);" @click="toPath('/privacy-policy')">
          Privacy Policy
        </a>
        <a href="javascript:void(0);" @click="toPath('/terms-conditions')">
          Terms & Conditions
        </a>
        <a href="javascript:void(0);">Support</a>
      </b-col>
      <b-col xl="3" md="4">
        <span>Links</span>
        <a href="https://en.zhejianglab.org/" target="_blank">Zhejiang Lab</a>
        <a href="http://zjdf.zhejianglab.org/home/#/home" target="_blank">
          Zhijiang Development Foundation
        </a>
        <a href="https://spj.science.org/journal/icomputing" target="_blank">
          Intelligent Computing
        </a>
      </b-col>
    </b-row>

    <div class="copyright">
      © Copyright 2024, All Rights Reserved by Zhejiang lab
    </div>
  </div>
</template>

<script>
import LogoText from "@/views/common/LogoText.vue";

export default {
  name: "CommonFooter",
  components: { LogoText },
  methods: {
    toPath(path) {
      if (path === this.$route.path) {
        return;
      }
      this.$router.push({ path });
      document.scrollTop = 0;
    },
  },
};
</script>
<style lang="scss" scoped>
.row {
  margin: 0;
  text-align: left;

  > div {
    span {
      color: #18181b;
      font-size: 16px;
      line-height: 28px;
      font-weight: bold;
    }

    a {
      color: #52525a;
      font-size: 14px;
      text-decoration: none;
    }

    > * {
      display: block;
      margin: 25px 0;
    }
  }
}

.copyright {
  margin-top: 30px;
  text-align: center;
  font-size: 13px;
  line-height: 20px;
  color: #52525a;
}
</style>
